<template>
    <div>
        <Horizontal ref="Horizontal" :countDown="countdown" :disableBtnForSign="disableBtnForSign"
            @getRedPacketOfSign="getRedPacketOfSign"
            v-if="$store.getters.currentPageVersion === 'Horizontal'&& $store.getters.signRedPacketStatus">
        </Horizontal>


        <Vertical ref="Vertical" :disableBtnForSign="disableBtnForSign" :countDown="countdown"
            @getRedPacketOfSign="getRedPacketOfSign"
            v-if="$store.getters.currentPageVersion === 'Vertical'&& $store.getters.signRedPacketStatus"></Vertical>

        <DialogWhenGetRedPacket @closeRedGetDialog="closeRedGetDialog" :dialogOpenType="dialogOpenType"
            :gotMoneyOfRedPacket="gotMoneyOfRedPacket" :isShowRedDialogInfo="isShowRedDialogInfo"
            @backImgLoadDone="backImgLoadDone" v-if="isOpenDialogOfRedPacket"></DialogWhenGetRedPacket>
    </div>
</template>

<script>
    import Horizontal from '@/components/Index/IndexRedPacket/SignRedPacket/Horizontal.vue'
    import Vertical from '@/components/Index/IndexRedPacket/SignRedPacket/Vertical.vue'
    import DialogWhenGetRedPacket from '@/components/Index/IndexRedPacket/RedPacketOfDialog/Index.vue' //领取红包时的交互弹窗画面
    export default {
        components: {
            Horizontal,
            Vertical,
            DialogWhenGetRedPacket
        },
        props: {

        },
        data() {
            return {
                disableBtnForSign: false, //签到红包是否不可领取
                countdown: 0, //签到红包倒计时
                countDownTimer: null, //签到红包倒计时器

                isOpenDialogOfRedPacket: false, //横板领取弹窗
                dialogOpenType: 'SIGN_RED_PACKET', //领取成功弹窗显示的红包类型
                gotMoneyOfRedPacket: 0, //领取成功弹窗显示的金额
                isShowRedDialogInfo: false,

            }
        },
        created() {
            this.$store.dispatch('getSignRedPacketInfo', this)
        },
        methods: {
            //开启签到红包倒计时
            redPacketOfSignStartCountdown(startTime) {
                console.log(startTime)
                if (this.countDownTimer !== null) {
                    //当前已开启倒计时,不再执行
                    return
                }
                console.log(startTime)
                if (startTime < 1) {
                    this.disableBtnForSign = false
                    this.countdown = 0
                    this.$store.commit('getUserEnterGameDate', {
                        enterDate: this.$common.timeFunc().timeStampS,
                        state: true
                    })
                    this.updateUserSignRedPacketState(true)
                    return
                }
                if (startTime === 60) {
                    //点击领取，未通过点击领取开启的倒计时无需同步到客户端
                    this.updateUserSignRedPacketState(false)
                }
                const that = this
                this.countdown = startTime
                this.disableBtnForSign = true
                this.countDownTimer = setInterval(() => {
                    that.countdown = that.countdown - 1
                    console.log(that.countdown)
                    if (that.countdown === 0) {
                        clearInterval(this.countDownTimer)
                        that.countDownTimer = null
                        that.disableBtnForSign = false
                        that.updateUserSignRedPacketState(true)
                    }
                }, 1000)

            },
            //领取签到红包
            getRedPacketOfSign(redPacketInfo) {
                // this.$emit('openSignRedPacketDialog', 20000)
                // this.redPacketOfSignStartCountdown(60)
                // setTimeout(()=> {
                //     this.$store.dispatch('getSignRedPacketInfo', this)
                // },1000)
                // return

                let requestData = {
                    role_id: this.$store.state.roleId,
                    token: this.$store.state.token,
                    user_id: this.$store.state.userId,
                    reward_id: this.$store.state.signRedPacketInfo.id
                }
                this.$api.redPacket.getSignRedPacket(requestData).then(res => {
                    if (res.data.code === 200) {
                        
                        if(this.$route.name === 'IndexRedPacket') {
                            console.log('竖版')
                            this.$emit('openSignRedPacketDialog', res.data.data)
                        }else {
                            //横板非首页
                            console.log('横板')
                            this.openSignRedPacketDialog('SIGN_RED_PACKET', res.data.data)
                        }
                        
                        if (this.$store.state.signRedPacketInfo.surplus_reward_nums > 0) {
                            this.redPacketOfSignStartCountdown(60)
                        }
                        //防止请求太快数据未更新
                        setTimeout(async () => {
                            await this.$store.dispatch('getSignRedPacketInfo', this)
                            
                        }, 2000)
                    }
                })
            },
            //横板领取红包成功，打开弹窗
            openSignRedPacketDialog(type, money) {
                this.isOpenDialogOfRedPacket = true
                this.dialogOpenType = type
                this.gotMoneyOfRedPacket = Number(money)
            },
            //将签到红包状态同步到客户端
            updateUserSignRedPacketState(state) {
                this.$store.commit('getUserEnterGameDate', {
                    enterDate: this.$common.timeFunc().timeStampS,
                    state
                })
                const statePack = this.$store.state.userEnterDate
                switch (this.$common.getNowSystem()) {
                    case 'ios':
                        this.$common.postMessageToIos('updateUserSignRedPacketState', JSON.stringify(statePack))
                        return
                    case 'Android':
                        console.log(JSON.stringify(statePack))
                        android.updateUserSignRedPacketState(JSON.stringify(statePack))
                        break;
                    default:
                        this.$common.postMessageToWindowParent('updateUserSignRedPacketState', statePack)
                        break;
                }
            },
            //红包背景图加载完成
            backImgLoadDone() {
                console.log('图片加载完成')
                this.isShowRedDialogInfo = true
            },
            //关闭红包领取成功的弹窗
            closeRedGetDialog() {
                this.$Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    overlay: false,
                    message: '',
                });
                //更新余额
                setTimeout(() => {
                    const initData = {
                        that: this,
                        roleId: this.$store.state.roleId !== '--' ? this.$store.state.roleId : ''
                    }
                    this.$Toast.clear();
                    this.isOpenDialogOfRedPacket = false
                    this.isShowRedDialogInfo = false
                    this.$store.dispatch('initForRedPacketMod', initData)
                }, 1500)
            },
        }
    }
</script>

<style scoped>

</style>