<template>
    <div class="dialog-red-get-box">
        <transition name="redPacket" appear>
            <Horizontal :dialogOpenType="dialogOpenType" 
                :gotMoneyOfRedPacket="gotMoneyOfRedPacket"
                :isShowRedDialogInfo="isShowRedDialogInfo"
                @closeRedGetDialog="closeRedGetDialog" 
                @backImgLoadDone="backImgLoadDone"
                ref="Horizontal"
                v-if="$store.getters.currentPageVersion === 'Horizontal'">
            </Horizontal>
            <Vertical :dialogOpenType="dialogOpenType" :gotMoneyOfRedPacket="gotMoneyOfRedPacket" :isShowRedDialogInfo="isShowRedDialogInfo" ref="Vertical"
                    @closeRedGetDialog="closeRedGetDialog" @backImgLoadDone="backImgLoadDone" v-else></Vertical>
        </transition>
    </div>
</template>

<script>
    import Horizontal from '@/components/Index/IndexRedPacket/RedPacketOfDialog/Horizontal.vue'
    import Vertical from '@/components/Index/IndexRedPacket/RedPacketOfDialog/Vertical.vue'
    export default {
        components: {
            Horizontal,
            Vertical
        },
        props: {
            dialogOpenType: String,
            gotMoneyOfRedPacket: Number,
            isShowRedDialogInfo:Boolean
        },
        data() {
            return {}
        },
        methods: {
            closeRedGetDialog() {
                this.$emit('closeRedGetDialog')
            },
            backImgLoadDone() {
                this.$emit('backImgLoadDone')
            }
        }
    }
</script>

<style scoped>
    .redPacket-enter,
    .redPacket-leave-to,
    .redPacket-enter {
        opacity: 0;
        transform: scale(0);
    }

    .redPacket-enter-to,
    .redPacket-leave {
        opacity: 1;
        transform: scale(1);
    }

    .redPacket-enter-active,
    .redPacket-leave-active {
        transition: 0.5s;
    }

    .dialog-red-get-box {
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.3);
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1001;
        overflow: auto;
    }
</style>