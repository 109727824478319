<template>
    <div class="exchange-box">
        <div class="exchange">
            <img src="@/assets/index/balance_iocn.svg" class="balance-icon">
            <div class="balance-count-box">
                我的余额：{{$store.state.gameRewardMoney}}&nbsp;元
            </div>
            <div class="exchange-btn-box">
                <div class="exchange-btn" @click="goExchange">
                    去兑换
                    <img src="@/assets/index/enter_index.svg" alt="">
                </div>  
            </div>
            <img  class="back-icon" src="@/assets/index/species.svg" alt="">
        </div>
    </div>  
</template>

<script>
    export default {
        methods:{
            goExchange() {
                this.$emit('goExchange')
            }
        }
    }
</script>

<style scoped>
    .exchange-box {
        width: 100%;
    }
    .exchange {
        width: 100%;
        height: 30px;
        background: linear-gradient(270deg, #96D3E8 0%, #CBEEF5 100%);
        border-radius: 5px;
        display: flex;
        align-items: center;
        position: relative;
        padding: 0 7.5px;
    }
    .balance-icon {
        width: 11.25px;
    }
    .balance-count-box {
        flex: 1;
        font-size: 8.75px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #305359;
        padding: 0 4px;
        display: flex;
        align-items: center;
        white-space: nowrap;
    }
    .balance-count-box>div {
        font-size: 9px;
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .exchange-btn-box {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .exchange-btn {
        height: 15px;
        background: #78B9CE;
        border-radius: 7.5px;
        display: flex;
        align-items: center;
        padding: 0 5px;
        font-size: 8px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        white-space: nowrap;
    }
    .exchange-btn>img {
        margin-left: 2px;
        width: 4px;
    }
    .back-icon {
        width: 22px;
        position: absolute;
        bottom: 0;
        left: 60%;
    }
</style>