<template>
    <div class="sign-box">
        <div class="sign-redpacket">
            <div class="title">
                {{$store.state.signRedPacketInfo.day}}天签到红包
            </div>
            <div class="get-box" v-if="$store.getters.isShowTipsOfSignRedPack">
                <div class="redpacket-count-box">
                    <img class="redpacket-count-icon" src="@/assets/index/sign_red.svg" alt="">
                    <div class="redpacket-count">
                        今日可领{{$store.state.signRedPacketInfo.today_reward_nums}}个，还剩{{$store.state.signRedPacketInfo.surplus_reward_nums}}个
                    </div>
                </div>
                <div v-if="$store.state.signRedPacketInfo.surplus_reward_nums>0">
                    <div class="sign-btn" @click="getRedPacketOfSign" v-if="!disableBtnForSign">
                        立即签到
                    </div>
                    <div class="sign-btn-Disable" v-else>
                        {{countDown}}s后可领取
                    </div>
                </div>
                <div v-else>
                    <div class="sign-btn-Disable">
                        已领完
                    </div>
                </div>
            </div>
            <div class="get-box" v-else>
                <div class="redpacket-count-box">
                    <img class="redpacket-count-icon" src="@/assets/index/sign_red.svg" alt="">
                    <div class="redpacket-count">
                        红包已领完，此活动{{$store.getters.signRedCloseDays}}天后消失
                    </div>
                </div>
                <div class="sign-btn-Disable">
                    已领完
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            disableBtnForSign:Boolean,
            countDown:Number
        },
        data() {
            return {
            }
        },
        methods: {
            getRedPacketOfSign() {
                this.$emit('getRedPacketOfSign')
            }
        }
    }
</script>

<style scoped>
    .sign-redpacket {
        width: 100%;
        padding: 10px 8px 8px 8px;
        background: linear-gradient(90deg, #FDF4E8 0%, #F8D7AF 100%);
        border-radius: 8px 8px 8px 8px;
    }
    .title {
        font-size: 15px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        padding-bottom: 8px;
    }
    .get-box {
        width: 100%;
        padding: 7px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: rgba(255,255,255,0.78);
        border-radius: 8px 8px 8px 8px;
    }
    .redpacket-count-box {
        display: flex;
        align-items: center;
        padding-bottom: 8px;
    }
    .redpacket-count-icon {
        width: 23px;
    }
    .redpacket-count {
        padding-left: 8px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #82534B;
    }
    .sign-btn,
    .sign-btn-Disable {
        width: 210px;
        font-size: 15px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        height: 34px;
        background: linear-gradient(180deg, #F2A878 0%, #E54438 100%);
        box-shadow: 0px 3px 6px 1px #FF9B9E;
        border-radius: 18px 18px 18px 18px;
        opacity: 1;
        border: 1px solid #CC7B52;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .sign-btn-Disable {
        background: #B2B2B2;
        box-shadow: none;
        border: none;
    }
</style>