<template>
    <div class="dialog-red-get-box">
        <Horizontal ref="Horizontal"
        @goExchange="goExchange"
            v-if="$store.getters.currentPageVersion === 'Horizontal'">
        </Horizontal>


        <Vertical ref="Vertical"
        @goExchange="goExchange"
        v-else></Vertical>
    </div>
</template>

<script>
    import Horizontal from '@/components/Index/IndexRedPacket/Exchange/Horizontal.vue'
    import Vertical from '@/components/Index/IndexRedPacket/Exchange/Vertical.vue'
    export default {
        components: {
            Horizontal,
            Vertical
        },
        props: {

        },
        data() {
            return {
               
            }
        },
        created() {
            
        },
        methods: {
            goExchange() {
                this.$router.push({
                    name:'ExchangeIndex'
                })
            }
        }
    }
</script>

<style scoped>

</style>