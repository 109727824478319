<template>
    <div class="image-box" v-if="dialogOpenType==='FG_RECHARGE_RED_PACKET'">
        <img src="@/assets/index/fg_red_packet_dialog.png" @load="backImgLoadDone" class="fg-back-img" v-if="dialogOpenType==='FG_RECHARGE_RED_PACKET'">
        <div class="money-text-fg" v-if="isShowRedDialogInfo">
            <img src="@/assets/index/fg_yuan.svg">
            <div>{{gotMoneyOfRedPacket | changMoney}}</div>
        </div>
        <img src="@/assets/index/dialog_close_icon.svg" class="close-icon" @click="closeRedGetDialog" v-if="isShowRedDialogInfo">
    </div>
    <div class="image-box" v-else>
        <img src="@/assets/index/sign_red_packet_dialog.svg" @load="backImgLoadDone" class="sign-back-img" v-if="dialogOpenType==='SIGN_RED_PACKET'">
        <div class="money-text-sign" v-if="isShowRedDialogInfo">
            <img src="@/assets/index/fg_yuan.svg">
            <div>{{gotMoneyOfRedPacket | changMoney}}</div>
        </div>
        <img src="@/assets/index/dialog_close_icon.svg" class="close-icon" @click="closeRedGetDialog" v-if="isShowRedDialogInfo">
    </div>
</template>

<script>
    export default {
        props: {
            dialogOpenType: String,
            gotMoneyOfRedPacket:Number,
            isShowRedDialogInfo:Boolean
        },
        data() {
            return {
            }
        },
        methods: {
            closeRedGetDialog() {
                this.$emit('closeRedGetDialog')
            },
            backImgLoadDone() {
                this.$emit('backImgLoadDone')
            }
        }
    }
</script>

<style scoped>
    .image-box {
        position: relative;
    }

    .fg-back-img {
        width: 309px;
    }
    .sign-back-img {
        width: 288px;
    }
    .money-text-fg,
    .money-text-sign {
        position: absolute;
        top: 102px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: flex-end;
    }
    .money-text-sign {
        
    }
    .money-text-fg>img,
    .money-text-sign>img{
        width: 16px;
    }

    .money-text-fg>div,
    .money-text-sign>div {
        font-size: 46px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: 500;
        color: #FC3550;
        line-height: 36px;
    }

    .close-icon {
        width: 26px;
        position: absolute;
        bottom: -46px;
        left: 50%;
        transform: translateX(-50%);
    }
    
</style>